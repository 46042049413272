























































































































































































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import PageHeading from '@/components/Presenter/PageHeading.vue';

export default Vue.extend({
  components: { PageHeading },
  data() {
    return {
      updated: false,
      tempTopic: '',
    };
  },
  computed: {
    ...mapGetters({
      currentSession: 'presenter/getCurrentSession',
      wordclouds: 'presenter/getSessionWordclouds',
    }),
    wordcloud(): any {
      if (this.wordclouds && this.currentSession) {
        if ('activeWordcloud' in this.currentSession) {
          const [wordcloud] = this.wordclouds.filter(
            (item: any) => item.id === this.currentSession.activeWordcloud
          );
          return wordcloud;
        }
      }
      return {};
    },
    words(): any[] {
      if (this.wordcloud) {
        const list = [];
        for (const key in this.wordcloud) {
          if (key !== 'id') {
            list.push([key, this.wordcloud[key]]);
          }
        }
        return list;
      }
      return [];
    },
    topic: {
      get(): string {
        if (this.tempTopic !== '') return this.tempTopic;
        if (this.currentSession) {
          if ('wordcloudTopic' in this.currentSession) {
            return this.currentSession.wordcloudTopic;
          }
        }
        return '';
      },
      set(value: string): any {
        this.tempTopic = value;
      },
    },
  },
  methods: {
    toggleWordcloudVisibility(state: boolean) {
      this.$store.dispatch('presenter/toggleWordcloudVisibility', {
        state,
      });
    },
    resetWordcloud() {
      if (
        confirm(this.$t('views.presenter.session.wordcloudsResetMsg') as string)
      ) {
        this.$store.dispatch('presenter/resetWordcloud');
      }
    },
    pauseWordcloud() {
      this.$store.dispatch('presenter/toggleWordcloudPaused', {
        state: true,
      });
    },
    continueWordcloud() {
      this.$store.dispatch('presenter/toggleWordcloudPaused', {
        state: false,
      });
    },
    submitTopicUpdate() {
      this.$store.dispatch('presenter/updateWordcloudTopic', {
        topic: this.tempTopic,
      });
    },
  },
});
