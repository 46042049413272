
















import Vue from 'vue';
import { mapGetters } from 'vuex';
export default Vue.extend({
  props: ['pollResults', 'pollId'],
  computed: {
    ...mapGetters({
      globalTVData: 'presenter/getTVData',
    }),
  },
  methods: {
    activateTVMode() {
      this.$store.dispatch('presenter/activateTVMode', {
        state: !this.globalTVData.activeTVPolls.includes(this.pollId),
        pollResults: this.pollResults,
        pollId: this.pollId,
      });
    },
  },
});
