






















import Vue from 'vue';
export default Vue.extend({
  name: 'SaveButton',
  props: {
    className: {
      type: String,
      required: false,
      default: '',
    },
    onClick: {
      type: Function,
      required: true,
    },
    highlighted: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    save() {
      this.onClick();
    },
  },
});
